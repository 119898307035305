import React, { useContext } from 'react';
import styled from 'styled-components';
import { Link } from 'gatsby';
import GoogleMapReact from 'google-map-react';

const MOBILE_BREAKPOINT = '700px';

const Container = styled.div`
  display: flex;
  background-color: white;
  max-width: 1700px;
  margin: 0px auto;
  //margin-top: -100px;

  position: absolute;
  top: 75vh;
  left: 50%;
  transform: translateX(-50%);
  width: 85%;

  @media screen and (max-width: 1200px) {
    margin-top: 15px;
  }

  @media screen and (max-width: ${MOBILE_BREAKPOINT}) {
    flex-direction: column;
    width: 100%;
    margin: 0 auto;
  }
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  flex-grow: 1;
  padding: 50px 20px 85px;
  text-align: center;

  @media screen and (max-width: ${MOBILE_BREAKPOINT}) {
    padding: 40px 20px 40px;
  }
`;

const Title = styled.h2`
  font-family: 'Oswald';
  font-style: normal;
  font-weight: 500;
  font-size: 36px;
  line-height: 168.5%;
  text-transform: uppercase;
  color: #2d2b2c;

  @media screen and (max-width: ${MOBILE_BREAKPOINT}) {
    font-size: 24px;
  }
`;

const Subtitle = styled.h3`
  margin-bottom: 0;
  font-family: 'Lato';
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 168.5%;
  text-transform: uppercase;
  text-decoration: underline;
  color: #a1c654;

  @media screen and (max-width: ${MOBILE_BREAKPOINT}) {
    margin-bottom: 20px;
    font-size: 16px;
  }
`;

const Hours = styled.div`
  display: flex;
  gap: 15px;
  font-family: 'Lato';
  font-style: normal;
  font-weight: normal;
  font-size: 24px;
  line-height: 150%;
  text-transform: uppercase;
  color: #2d2b2c;

  .days {
    text-align: right;
  }

  @media screen and (max-width: 1400px) {
    font-size: 16px;
  }

  @media screen and (max-width: ${MOBILE_BREAKPOINT}) {
    margin-bottom: 20px;
    .days {
      text-align: left;
    }
  }
`;

const ButtonContainer = styled.div`
  width: 100%;

  .map-btn {
    /* display: flex;
        justify-content: center; */
    text-align: center;
    min-width: 40%;
    margin: 0px 5px;
  }

  @media screen and (max-width: ${MOBILE_BREAKPOINT}) {
    .map-btn {
      width: 100%;
      margin: 7px 5px;
    }
  }
`;

const MapContainer = styled.div`
  width: 40vw;
  height: 40vw;

  @media screen and (max-width: ${MOBILE_BREAKPOINT}) {
    width: 60vw;
    height: 60vw;
    margin: 0 auto;
  }

  @media screen and (max-width: 768px) {
    width: 90vw;
    height: 90vw;
  }
`;

const markerPosition = {
  position: 'absolute',
  transform: 'translate(-50%, -100%)',
};

const Mark = () => (
  <svg
    width="42"
    height="42"
    xmlns="http://www.w3.org/2000/svg"
    style={markerPosition}
  >
    <g>
      <path
        stroke="null"
        id="svg_2"
        fill="#e74c3c"
        d="m21.536638,0.484914c-7.602285,0 -13.765086,6.162801 -13.765086,13.765086c0,2.445023 0.656595,4.731748 1.77432,6.720803c0.185657,0.330362 0.380261,0.655562 0.591555,0.968718l11.399212,19.840651l11.399212,-19.840651c0.175505,-0.259816 0.326921,-0.535118 0.483499,-0.806978l0.1084,-0.16174c1.116693,-1.989055 1.773975,-4.27578 1.773975,-6.720803c0,-7.602285 -6.163317,-13.765086 -13.765086,-13.765086zm0,6.882543c3.800884,0 6.882543,3.081487 6.882543,6.882543c0,3.800884 -3.081659,6.882543 -6.882543,6.882543c-3.801056,0 -6.882543,-3.081659 -6.882543,-6.882543c0,-3.801056 3.081487,-6.882543 6.882543,-6.882543z"
      />
      <path
        stroke="null"
        id="svg_3"
        fill="#c0392b"
        d="m21.482759,6.663794c-4.249223,0 -7.693965,3.458063 -7.693965,7.723717c0,4.265037 3.444742,7.723717 7.693965,7.723717c4.248608,0 7.693965,-3.45868 7.693965,-7.723717c0,-4.265654 -3.445358,-7.723717 -7.693965,-7.723717zm0,3.089487c2.54978,0 4.616379,2.074745 4.616379,4.63423s-2.066599,4.63423 -4.616379,4.63423s-4.616379,-2.074745 -4.616379,-4.63423s2.066599,-4.63423 4.616379,-4.63423z"
      />
    </g>
  </svg>
);

const GoogleMap = ({ coordinates }) => {
  const { lat } = coordinates;
  const { lng } = coordinates;

  const mapProps = {
    center: { lat, lng },
    zoom: 17,
  };
  const { center, zoom } = mapProps;

  return (
    <GoogleMapReact
      bootstrapURLKeys={{ key: 'AIzaSyDHdRRXlkEtxxkihYsWmGqwt7SdTenC-EI' }}
      defaultCenter={center}
      center={center}
      defaultZoom={zoom}
    >
      <Mark lat={lat} lng={lng} />
    </GoogleMapReact>
  );
};

export default function LocationMap({
  lat,
  long,
  title,
  street,
  hours,
  orderSlug,
  exactAddress,
  specialHrs="",
}) {
  const coordinates = { lat: parseFloat(lat), lng: parseFloat(long) };
  const googlemaplink = `https://www.google.com/maps/search/?api=1&query=${exactAddress}`;
  return (
    <Container>
      <Content>
        <div>
          <Title>{title}</Title>
          <Subtitle>{street}</Subtitle>
        </div>
        <div style={{padding:"15px"}}><strong>{specialHrs}</strong></div>
        <Hours>
          <div className="days">
            {hours.map((day, index) => (
              <>
                <div key={index}> {day.Day} </div>
                <br />
              </>
            ))}
          </div>
          <div>
            {hours.map((day, index) => (
              <>
                <div key={index}> {day.Hours} </div>
                <br />
              </>
            ))}
          </div>
        </Hours>
        <ButtonContainer>
          <Link
            to={orderSlug}
            className="map-btn hoc-button hoc-button--hover-dark hoc-button--inline-block"
          >
            Order Online
          </Link>
          <a
            target="_blank"
            href={googlemaplink}
            className=" map-btn hoc-button-outline hoc-button--inline-block"
            rel="noreferrer"
          >
            Visit This Location
          </a>
        </ButtonContainer>
      </Content>
      <MapContainer>
        <GoogleMap coordinates={coordinates} />
      </MapContainer>
    </Container>
  );
}
