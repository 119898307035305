import React from 'react';
import styled from 'styled-components';

import MapPinLine from '../../../static/mapPinLine.svg';
import CalendarBlank from '../../../static/calendarBlank.svg';
import ChatsCircle from '../../../static/chatsCircle.svg';

const MOBILE_BREAKPOINT = '768px';

const Container = styled.div`
  margin-top: 75vh;

  display: flex;
  justify-content: space-evenly;
  align-items: center;
  padding: 90px 20px;
  text-align: center;
  background: #f5f5f5;

  @media screen and (max-width: ${MOBILE_BREAKPOINT}) {
    flex-direction: column;
    padding: 50px 20px 80px;
    gap: 85px;
  }
`;

const Title = styled.h2`
  font-family: 'Oswald';
  font-style: normal;
  font-weight: 500;
  font-size: 36px;
  line-height: 130%;
  text-transform: uppercase;
  color: #2d2b2c;
`;

const IconContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 80px;

  img {
    width: 32px;
    height: 32px;
  }
`;

const Description = styled.div`
  font-family: 'Lato';
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 150%;
  color: #2d2b2c;

  p {
    margin: 0;
  }

  .today {
    text-transform: uppercase;
    color: #c0c0c0;
  }
`;

export default function StoreFacts({ storeFacts, storeHours, specialHrs="" }) {
  const newDate = new Date();
  const currentWeekday = newDate.getDay() === 0 ? 7 : newDate.getDay();
  const currentDay = newDate.getDate();
  const currentMonth = newDate.getMonth();

  return (
    <Container>
      <div>
        <Title>Address</Title>
        <IconContainer>
          <img src={MapPinLine} />
        </IconContainer>
        <Description>
          <p>{storeFacts.Location_Adress}</p>
          <p>{storeFacts.Location_City}</p>
        </Description>
      </div>
      <div>
        <Title>Hours open</Title>
        <IconContainer>
          <img src={CalendarBlank} />
        </IconContainer>
        <Description>
          <p className="today">
            TODAY{' '}
            {`(${(currentDay < 10 && '0') + currentDay}/${
              (currentMonth + 1 < 10 && '0') + (currentMonth + 1)
            })`}
          </p>
          <p>{storeHours[currentWeekday - 1].Hours}</p>
          <p className="today"><strong>{specialHrs}</strong></p>
        </Description>
      </div>
      <div>
        <Title>Contact</Title>
        <IconContainer>
          <img src={ChatsCircle} />
        </IconContainer>
        <Description>
          <p>{storeFacts.Contact_Email}</p>
          <p>{storeFacts.Contact_Phone}</p>
        </Description>
      </div>
    </Container>
  );
}
